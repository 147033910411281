import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'

import Layout from '../components/Layout'
import scssVars from '../components/scss-variables'
import Main from '../components/layouts/Main'
import HeroSection from '../components/layouts/marketing/HeroSection'
import FeaturedServices from '../components/RepairServices/FeaturedServices'
import SEO from '../components/seo'
import { HTMLContent } from '../components/Content'

const Tile = styled(Link)`
  position: relative;
  box-shadow: 1px 1px 2px 0 #d1d1d1;
  text-align: center;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    text-align: left;
    box-shadow: none;
    &:hover {
      text-decoration: none;
    }
  }
`

const TileHeader = styled.h3`
  font-family: 'Montserrat', Italic ,sans-serif;
  font-size: 14px;
  font-style: italic;
  letter-spacing: 0.05em;
  color: #2C2010;
  text-decoration: underline;
  position: absolute;
  bottom: 5px;
  text-transform: none;
  left: 0;
  right: 0;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    position: relative;
    bottom: 0;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: 0;
    color: ${scssVars.darkBrown};
    text-decoration: none;
    text-transform: uppercase;
    font-style: unset;
  }
`

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-style: italic;
  position: absolute;
  bottom: 0;
`

const StyledBtn = styled(Link)`
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  @media only screen and (min-width: 992px) {
    display: block !important;
    width: 382px !important;
    margin: 3rem auto 4rem auto;
  }
`

const RepairItemPageTemplate = ({ frontmatter, html, repairServices, heroImg, isWomensCategory, url }) => {
  const iconType = (url, item) => {
    if (url.includes('womens')) {
      return [item.icon, item.srcMobile]
    }

    if (url.includes('mens')) {
      if (item.mensIcon) {
        return [item.mensIcon, item.mobileMensIcon]
      } else {
        return [item.icon, item.srcMobile]
      }
    }

    if (!url.includes('shoes') && !url.includes('boots')) {
      if (item.otherIcon && item.otherMobileIcon) {
        return [item.otherIcon, item.otherMobileIcon]
      } else {
        return [item.icon, item.srcMobile]
      }
    }
  }
  return (
    <Main>
      <SEO title={frontmatter.title} pathname={frontmatter.path} description="Services we offer for all your leather repair and care needs" />
      <HeroSection title={frontmatter.title}
        breadcrumb={{ title: 'Repair Services', path: '/repair-services' }}
        mobileImg={`${heroImg}?fit=scale&w=992&fm=jpg`}
        desktopImg={`${heroImg}?fit=scale&w=1360&fm=jpg`}
      >
        <HTMLContent content={html} />
      </HeroSection>
      <Container className="mt-5">
        <Row>
          {repairServices.map((item, i) => (
            <Col xs={6} lg={3} key={i} className="mb-lg-4" style={{ padding: '0.75rem' }}>
              <Tile to={item.path} className="d-block" state={{ prevPage: url }}>
                {item.src && (<picture>
                  <source srcSet={iconType(url, item)[0]} media="(min-width: 992px)" alt={item.title} />
                  <img className="w-100" src={iconType(url, item)[1]} alt={item.title}/>
                </picture>)}
                <TileHeader>{item.title}</TileHeader>
              </Tile>
              <div className="d-none d-lg-block mt-3">
                <StyledLink to={item.path} state={{ prevPage: url }}>Read More</StyledLink>
              </div>
            </Col>
          ))}
        </Row>
        <StyledBtn to="/start-a-repair" className="btn btn-primary">Start A Repair</StyledBtn>
      </Container>
      <FeaturedServices />
    </Main>
  )
}

RepairItemPageTemplate.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  html: PropTypes.string.isRequired,
  repairServices: PropTypes.array.isRequired,
  heroImg: PropTypes.string.isRequired,
  isWomensCategory: PropTypes.bool,
  url: PropTypes.string
}

const RepairItemPage = ({ data, location }) => {
  const { markdownRemark: { frontmatter, html }, allMarkdownRemark: { edges: relatedItems } } = data
  const heroImg = frontmatter.heroImg ? frontmatter.heroImg : 'https://leatherspa.imgix.net/RepairServiceHome.jpg'
  const repairServices = relatedItems.map(item => item.node.frontmatter)
  const url = location.pathname ? location.pathname : ''
  const isWomensCategory = url.includes('womens')

  return (
    <Layout>
      <RepairItemPageTemplate
        frontmatter={frontmatter}
        html={html}
        repairServices={repairServices}
        heroImg={heroImg}
        isWomensCategory={isWomensCategory}
        url={url}
      />
    </Layout>
  )
}

RepairItemPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
}

export default RepairItemPage

export const repairItemPageQuery = graphql`
  query RepairItemPage($id: String!, $slug: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        lead
        path
        heroImg
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { relatedItems: { in: [$slug] } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            lead
            src
            srcMobile
            icon
            mensIcon
            mobileMensIcon
            otherIcon
            otherMobileIcon
          }
          html
        }
      }
    }
  }
`
