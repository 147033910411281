import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link }  from 'gatsby'
import styled from 'styled-components'

import scssVars from '../scss-variables'

const SectionHeading = styled.h2`
  font-weight: 200;
  color: ${scssVars.darkBrown};
  text-align: center;
  display: block;
  flex-wrap: wrap;
  display: flex;
  font-size: 44px;
  line-height: 1.25;
  flex-direction: column;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    display: flex;
    flex-direction: row;
    text-align: left;
  }
`

const SectionSpan = styled.span`
  border: 1px solid ${scssVars.brown};
  height: 1px;
  margin: 0 auto 40px;
  width: 25%;
  display: block;
  margin: 25px auto;
  height: 1px;
  @media only screen and (min-width: ${scssVars.desktopMin}) {
    margin-left: 30px;
    margin-bottom: 0;
    flex-grow: 1;
  }
`

const StyledMailTo = styled.a`
  font-family: inherit;
`

const StyledLink = styled(Link)`
  font-style: italic;
  text-decoration: underline;
`

export const FeaturedServices = () => {
  return (
    <section className="bg-clr-cararra">
      <Container>
        <SectionHeading className="pt-5">
          Featured Services
          <SectionSpan />
        </SectionHeading>
        <Row className="py-5">
          <Col className="pb-5 pb-lg-0" xs={12} lg={4}>
            <img className="w-100" alt="TODO" src="https://leatherspa.imgix.net/featured-services/event-shoe-shine-chair@2x.png?fm=jpg" />
            <h4 className="my-3">Event Shoe Shine Chair</h4>
            <p className="mb-0">
              Utilizing our high-quality care products, uniformed shoeshine expert and custom-designed leather, mahogany, and brass shoe shine chair, Leather Spa is pleased to offer Shoe Shine event services within the Tri-State area. Contact <StyledMailTo href="mailto: events@leatherspa.com">events@leatherspa.com</StyledMailTo> for more information and bookings. <span className="font-weight-bold">This service is currently unavailable due to COVID</span>.
            </p>
          </Col>
          <Col className="pb-5 pb-lg-0" xs={12} lg={4}>
            <img className="w-100" alt="TODO" src="https://leatherspa.imgix.net/featured-services/embossing@2x.png?fm=jpg" />
            <h4 className="my-3">Embossing &amp; Monogramming</h4>
            <p className="mb-0">
              We offer high quality silver or gold foil and natural heat-set finish embossing at all our Manhattan locations.
            </p>
            <StyledLink to="/repair-services/embossing-monogramming">Read more</StyledLink>
          </Col>
          <Col className="pb-5 pb-lg-0" xs={12} lg={4}>
            <img className="w-100" alt="TODO" src="https://leatherspa.imgix.net/featured-services/corporate@2x.png?fm=jpg" />
            <h4 className="my-3">Corporate Services</h4>
            <p className="mb-0">
              For decades, Leather Spa is the trusted, go-to accessory repair service provider for the world’s leading luxury, fashion and accessory companies.
            </p>
            <StyledLink to="/customer-services/corporate">Read more</StyledLink>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FeaturedServices
